import { redirect } from 'react-router'
import { serverOnly$ } from 'vite-env-only/macros'
import { type Route } from './+types/$'
import Page from '~/components/cms/page'
import { sdk } from '~/data/sdk.server'
import { getSampleResults } from '~/domain/calc/products.server'
import { enhancePageWithMarkdown } from '~/domain/cms/markdown.server'
import { makeMetaTitle, mergeRouteModuleMeta } from '~/utils/meta'
import { type SEOHandle } from '~/utils/sitemap'

export const meta: Route.MetaFunction = mergeRouteModuleMeta(({ data }) => {
	return [
		{
			name: 'og:title',
			content: makeMetaTitle(data?.title),
		},
		{ title: makeMetaTitle(data?.title) },
	]
})

const sitemapIgnoredSlugs = ['/', 'whats-next']

export const handle: SEOHandle = {
	getSitemapEntries: serverOnly$(async () => {
		const pagesData = await sdk.PagesForSitemap()
		return pagesData.pages
			.filter((page) => !sitemapIgnoredSlugs.includes(`${page.slug}`))
			.map((page) => {
				return { route: `/${page.slug}`, priority: 0.7 }
			})
	}),
}

export async function loader({ params }: Route.LoaderArgs) {
	const slug = params['*']!
	const pageData = await sdk.Page({ slug: slug })
	if (!pageData.page) {
		if (slug === 'dotace') {
			throw redirect('/')
		}
		throw new Response('Page not found', { status: 404 })
	}
	return {
		pageContent: enhancePageWithMarkdown(pageData.page.content),
		title: pageData.page.pageTitle,
		sampleResults: await getSampleResults(),
	}
}

export default function Index({ loaderData }: Route.ComponentProps) {
	const data = loaderData
	return data.pageContent ? (
		<Page pageContent={data.pageContent} sampleResults={data.sampleResults} />
	) : null
}
